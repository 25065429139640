.input {
  display: flex;
  flex-direction: column;
  position: relative;
}

.label {
  color: #747a8b;
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
}

input,
textarea {
  padding: 17px 20px;
  color: #474e5d;
  border: 1px solid #d5ddea;
  background: #ffffff;
  border-radius: 5px;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 25px;

  &::placeholder {
    color: #adb1c9;
  }
  &:focus,
  &:hover {
    outline: none;
    border: 2px solid #474e5d;
    padding: 16px 19px;
  }
}
textarea {
  resize: none;
  &:read-only {
    cursor: unset;
  }
}
