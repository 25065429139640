.app {
  display: flex;
  flex-wrap: wrap;
  max-width: 1390px;
  width: 100%;
}

.app-header {
  display: flex;
  justify-content: space-between;
  flex-basis: 100%;

  .header-text {
    font-size: 30px;
    line-height: 30px;
    font-weight: 700;
    color: #191919;
    margin-bottom: 36px;
  }
}

button {
  border-radius: 5px;
  border: 0;
  font-weight: 700;
  cursor: pointer;
  display: block;
}

.main-button {
  width: 152px;
  height: 40px;
  background: #108af9;
  border-radius: 5px;
  border: 0;
  margin-left: 20px;
  color: white;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  display: block;
}

.form-container {
  flex-grow: 1;
  padding-right: 30px;
}

.input-row {
  display: flex;
  justify-content: space-between;

  > * {
    flex-grow: 1;
  }
  > *:first-child {
    margin-right: 30px;
  }
}

.preview-container {
  min-height: 200px;
  padding: 25px;
  font-family: "proxima-nova", sans-serif;
  background-color: #ffff;
  border-radius: 7px;
  border: 1px solid #073d7d07;
  box-shadow: 0px 7px 13px #073d7d07;
  position: relative;
}

.form-button {
  display: flex;
  justify-content: flex-end;
  flex-basis: 100%;
  margin-bottom: 25px;
}

.signIn-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.preview-header-menu {
  display: flex;
  margin: 10px 30px 50px;
  border-bottom: 1px solid #eaeaf3;
  cursor: pointer;

  .preview-menu-item {
    position: relative;
    margin-right: 35px;
    padding: 17px 0;
    font-size: 16px;
    font-weight: 400;
    color: #747a8b;
  }

  .active-item {
    font-weight: 700;
    color: #d7282f;
    &::before {
      content: " ";
      position: absolute;
      width: 100%;
      border-bottom: 2px solid #d7282f;
      bottom: -1px;
    }
  }
}
.buttons-label {
  color: #747a8b;
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
}

.move-buttons {
  display: flex;
  margin-top: 15px;
  height: 60px;

  .arrow-button {
    background-image: url(../../icons/circle-arrow.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 20px;
    height: 20px;
    cursor: pointer;

    &:first-child {
      align-self: center;
    }

    &:last-child {
      align-self: center;
      transform: rotate(180deg);
    }

    &:nth-child(2) {
      align-self: flex-start;
      transform: rotate(90deg);
    }

    &:nth-child(3) {
      align-self: flex-end;
      transform: rotate(270deg);
      margin-left: -20px;
    }
  }
}

.loader {
  background: url(../../icons/loader.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 20px;
  height: 20px;
  margin: 0 auto;
  animation-name: rotate-s-loader;
  animation-iteration-count: infinite;
  animation-duration: 1s;
  animation-timing-function: linear;

  @keyframes rotate-s-loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
